@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  background: black;
  scroll-behavior: smooth;
}

.custom-left-border-bt {
  position: relative;
}

/* .bg-desktop-css {
  background-size: cover !important;
} */

@font-face {
  font-family: "Simplon BP";
  font-weight: 300;
  src: url("./assets/fonts/SimplonBP-Light.otf");
}

@font-face {
  font-family: "Simplon BP";
  font-weight: 400;
  src: url("./assets/fonts/SimplonBPRegular.otf");
}

@font-face {
  font-family: "Simplon BP";
  font-weight: 700;
  src: url("./assets/fonts/SimplonBP-Bold.otf");
}

@font-face {
  font-family: "Simplon BP Mono";
  font-weight: 400;
  src: url("./assets/fonts/SimplonBPMono-Regular.otf");
}

@font-face {
  font-family: "Simplon BP Mono";
  font-weight: 500;
  src: url("./assets/fonts/SimplonBPMono-Medium.otf");
}

.custom-btn-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(180deg, #4D4D4D 60%, #252525 100.16%);
}

.main__bg {
  background-image: url("./assets/kawa-bg.png");
  background-origin: content-box;
  /* padding: 40px; */
}

.bg-black .custom-border-image:last-child {
  border-right: 0;
}
/* .hover-custom-staking:hover {
  border:1px solid #ff1158;
  background: #ff1158;
} */
.hover-custom-staking:hover a {
  opacity: 1;
}
.bg__filter {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}
/* .custom-footer-new {
  position: relative;
  top: -1px;
} */
.nav {
  width: calc(100vw - 80px);
  margin: auto;
  /* max-width: 1648px; */
}
.min-body-custom {
  width: calc(100vw - 80px);
  margin: auto;
}

.custom-btn-discord {
  background: rgba(87, 98, 242, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 8px -2px rgba(16, 23, 46, 0.25);
  backdrop-filter: blur(32px);

  border-radius: 8px;
}
.hover-line {
  border-bottom: 1px solid transparent;
}

.wrapper-connect-walle {
  position: relative;
}
.App {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.wrapper-connect-wallet button {
  text-transform: uppercase;
  font-weight: 700;
}
#wallet-address {
  position: absolute;
  bottom: -30px;
  /* left: 50%; */
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #fff !important;
}
#wallet-address-connect {
  position: absolute;
  bottom: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #fff !important;
}
.custom-footer-mobile {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.wrapper-connect-wallet:hover #wallet-address {
  color:#fff;
}

@media (min-width: 1200px) {
  .main__bg {
    width: calc(100vw - 80px);
  }
  .min-body-custom {
    width: calc(100vw - 80px);
  }
}
.hover-line:hover {
  border-bottom: 1px solid #ff2e59;
}

@media (min-width: 768px) {
  .custom-width-staking {
    width: calc(50% + 3px);
  }
}

@media only screen and (min-width: 500px) and (max-width: 1280px) {
  .custom-last-team {
    border-right: 1px solid #505050;
  }
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
  .width-custom-center-mobile {
    width: calc(100% / 2);
  }
}
@media (max-width: 499px) {
  .mobile-border-team {
    border-left: none;
  }
}
@media only screen and (max-width: 640px) {
  .main__bg {
    background-position: 75% 0%;
  }

  .banner__bg {
    background-size: 200% !important;
  }

  .bg__filter {
    backdrop-filter: none;
    background: none;
  }

  .nav {
    width: 100vw;
    margin: 0;
  }
  .min-body-custom {
    width: 100vw;
    margin: 0;
  }

  .main__bg {
    padding: 0;
  }
}

@media only screen and (min-width: 1766px) {
  /* .nft {
    min-height: 360px;
  } */
}

@media only screen and (min-width: 960px) {
  /* .nft {
    max-height: 268px;
  } */
}
@media (max-width: 768px) {
  .custom-left-border-bt::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 25px;
    background: #505050;
    left: -21px;
    bottom: -12px;
  }

  .custom-bg-new {
    background: #140105;
  }
  .bg-mobile-css {
    background: #000 !important;
  }
}

@media only screen and (max-width: 765px) {
  /* .nft {
    min-height: 300px !important;
  } */
}

@media only screen and (max-width: 820px) {
  /* .nft {
    max-height: 150px !important;
  } */
}

@media only screen and (max-width: 540px) {
  #wallet-address {
    position: absolute;
    bottom: 0;
    font-size: 13px;
  }
  /* .nft {
    min-height: 270px !important;
  } */
}

@media only screen and (max-width: 420px) {
  .btn-discord-mobile {
    width: calc(100vw - 30px);
    margin: auto;
  }
  /* .nft {
    min-height: 210px !important;
  } */
}

@media only screen and (max-width: 412px) {
  /* .nft {
    min-height: 200px !important;
  } */
}

@media only screen and (max-width: 390px) {
  /* .nft {
    min-height: 200px !important;
  } */
}

@media only screen and (max-width: 375px) {
  /* .nft {
    min-height: 185px !important;
  } */
}

@media only screen and (max-width: 360px) {
  /* .nft {
    min-height: 180px !important;
  } */
}

.flex-40 {
  flex: 40%;
}

/* animations */
.forward-anim {
  animation: anim 0.5s ease 0s 1 normal forwards;
}

.backward-anim {
  animation: anim-reverse 0.5s ease 0s 1 normal forwards;
}

.back-to-place-anim {
  animation: back-to-place 0.5s ease 0s 1 normal forwards;
}

.scale-up-anim {
  animation: scale-up 1s ease 0s 1 normal forwards;
}

.fade-anim:not(:hover) {
  display: block;
  animation: out-anim 0.5s ease-out 0s 1 normal forwards;
}

@keyframes anim {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-reverse {
  0% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(-3px);
  }
}

@keyframes back-to-place {
  0% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(3px);
  }
}

@keyframes out-anim {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.6);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 767px) {
  .custom-bg-section {
    background: none !important;
  }
}
